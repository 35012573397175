.slider-container {
  position: relative;
  width: 100%;
  height: 500px;
  /* Set a fixed height for the slider */
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the area while maintaining aspect ratio */
}

.newsTitle {
  background-color: rgba(0, 74, 124, .85);
  height: 100px;
  text-decoration: none;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 350px;
  /* Adjust based on your current design */
  text-align: center;
  width: 100%;
  height: 25%;
  color: white;
  font-size: 16px;
  line-height: normal;
  text-overflow: ellipsis;
}

@media (max-width: 870px) {
  .newsTitle {
    /* bottom: 100px; */
    /* background-color: rgba(0, 74, 124, .85); */
    /* 5px higher than on larger screens */
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 20px;
  position: absolute;
  z-index: 10;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin: 0 5px;
}

/* Inactive dots */
.swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1 !important;
  /* White color for inactive dots */
}

/* Active dot */
.swiper-pagination-bullet-active {
  background-color: #004a7c !important;
  /* Your specified color for the active dot */
}


.swiper-button-next,
.swiper-button-prev {
  color: #004a7c !important;
  /* background-color: white;
  width: 200px;
  height: 200px;
  border-radius: 100px; */
  opacity: 0;
  /* Start with opacity 0 */
  transition: opacity 0.3s;
  /* Smooth transition for opacity change */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  /* Ensure arrows are on top */
}

.slider-container:hover .swiper-button-next,
.slider-container:hover .swiper-button-prev {
  opacity: 1;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}